/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Image } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"www.fit-okna.cz"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="pb--60 pt--60" name={"tr1tw3092n"}>
          
          <ColumnWrap className="column__flex --center el--2" columns={"2"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center" content={"BohemiaOkno"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Text className="text-box text-box--center fs--24" content={"Přední český výrobce \nhliníkových oken a dveří pro bytové domy, rodinné domy, školy, privátní a státní instituce, výrobní a kancelářské objekty a další velké investiční celky.\nDíky získaným zkušenostem, patří mezi přední dodavatele plastových a hliníkových oken a dveří v České republice."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--l8w pb--60 pt--60" name={"8vlk5dm9eaw"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/40398/1733af4c2aa04a67ace3a95d69fcf0e3_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/40398/1733af4c2aa04a67ace3a95d69fcf0e3_s=350x_.jpg 350w, https://cdn.swbpg.com/t/40398/1733af4c2aa04a67ace3a95d69fcf0e3_s=660x_.jpg 660w, https://cdn.swbpg.com/t/40398/1733af4c2aa04a67ace3a95d69fcf0e3_s=860x_.jpg 860w, https://cdn.swbpg.com/t/40398/1733af4c2aa04a67ace3a95d69fcf0e3_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/40398/4bd0efe4a4fb4651b5a36eac5c75534d_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/40398/4bd0efe4a4fb4651b5a36eac5c75534d_s=350x_.jpg 350w, https://cdn.swbpg.com/t/40398/4bd0efe4a4fb4651b5a36eac5c75534d_s=660x_.jpg 660w, https://cdn.swbpg.com/t/40398/4bd0efe4a4fb4651b5a36eac5c75534d_s=860x_.jpg 860w, https://cdn.swbpg.com/t/40398/4bd0efe4a4fb4651b5a36eac5c75534d_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/40398/6adb18be5c8f4f0ab48736df12ce74d8_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/40398/6adb18be5c8f4f0ab48736df12ce74d8_s=350x_.jpg 350w, https://cdn.swbpg.com/t/40398/6adb18be5c8f4f0ab48736df12ce74d8_s=660x_.jpg 660w, https://cdn.swbpg.com/t/40398/6adb18be5c8f4f0ab48736df12ce74d8_s=860x_.jpg 860w, https://cdn.swbpg.com/t/40398/6adb18be5c8f4f0ab48736df12ce74d8_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/40398/4400fb8e484949f2a191db6cea58ea4b_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/40398/4400fb8e484949f2a191db6cea58ea4b_s=350x_.jpg 350w, https://cdn.swbpg.com/t/40398/4400fb8e484949f2a191db6cea58ea4b_s=660x_.jpg 660w, https://cdn.swbpg.com/t/40398/4400fb8e484949f2a191db6cea58ea4b_s=860x_.jpg 860w, https://cdn.swbpg.com/t/40398/4400fb8e484949f2a191db6cea58ea4b_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/40398/cba6ef79995f4d9d87f80cf7ef1e8d22_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/40398/cba6ef79995f4d9d87f80cf7ef1e8d22_s=350x_.jpg 350w, https://cdn.swbpg.com/t/40398/cba6ef79995f4d9d87f80cf7ef1e8d22_s=660x_.jpg 660w, https://cdn.swbpg.com/t/40398/cba6ef79995f4d9d87f80cf7ef1e8d22_s=860x_.jpg 860w, https://cdn.swbpg.com/t/40398/cba6ef79995f4d9d87f80cf7ef1e8d22_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}